.root {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 2rem;
  margin-top: 2rem;
}

.root a {
  text-decoration: none;
}
